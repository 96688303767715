<template>
    <div>

    </div>
</template>

<script>
    import {hostLogin} from "../api/adminLogin";
    export default {
        name: "hostLogin",
        data() {
            return {
                linkCode: null
            }
        },
        mounted() {
            this.linkCode = this.$route.query.linkCode;
            hostLogin(this.linkCode).then(res => {
                if (res.data.code === 200) {
                    window.sessionStorage.setItem('token', res.data.data.tokenInfo.tokenValue)
                    //保存比赛信息
                    window.sessionStorage.setItem('event',JSON.stringify(res.data.data.event))
                    window.sessionStorage.removeItem('userInfo')
                    window.sessionStorage.setItem('roleFlag', "3");
                    this.$router.push('/index')
                }
            })
        },
        methods: {}
    }
</script>

<style scoped>

</style>
